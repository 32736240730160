import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import "./index.css";
import store from "./store";
import { createI18n } from 'vue-i18n';
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import ApiService from "./services/api.service";
import TokenService from "./services/storage.service";
import en from './locales/en.json';
import fr from './locales/fr.json';
// import mixpanel from "mixpanel-browser";

Pusher;



const i18n = createI18n({
  locale: TokenService.getLang(), // Langue par défaut
  fallbackLocale: TokenService.getLang(), // Langue de secours
  messages: {
    en,
    fr,
  },
});

ApiService.init("https://test1.csetrainings.com/");
if (TokenService.getToken()) {
  ApiService.setHeader();
}
ApiService.setHeaderLang();
// mixpanel.init("575d1cf519704fb33bf51e6eee886995", {
//   debug: true,
//   track_pageview: true,
//   persistence: "localStorage",
// });
window.Echo = new Echo({
  auth: {
    headers: {
      Authorization: `Bearer ${TokenService.getToken()}`,
    },
  },
});

createApp(App)
.use(store)
.use(i18n)
.use(router)
.mount('#app')
